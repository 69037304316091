import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';
import Icon from '@guestyci/foundation/Icon';

import { ReactComponent as Arrow } from 'assets/icons/arrow_down.svg';
import { ReactComponent as IconGuests } from 'assets/icons/guests.svg';

import PriceConverter from 'components/PriceConverter';
import { RatePlan, RatePlanV2 } from 'components/RatePlan';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { GROUP_RESERVATIONS } from '../../constants/featureToggleNames';

const useStyles = createStyles(() => ({
  root: {
    borderRadius: 4,
    border: '1px solid  #D4D8E1',
    padding: [25, 15],
    margin: [10, 0],
  },
  collapseWrapper: {
    display: ({ collapsed }) => {
      return collapsed ? 'none' : 'block';
    },
    '& button *': {
      color: '#242428',
    },
  },
  arrow: {
    transform: ({ collapsed }) => (collapsed ? 'rotate(-90deg)' : 'rotate(0deg)'),
  },
  titleWrapper: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  propertyName: {
    maxWidth: 190,
  },
}));

const GuestsCount = ({ count }) => {
  const guestsPluralLabel = t(
    `
  {
    count,
    plural,
    =0 {0 guests}
    one {# Guest}
    other {# Guests}
  }
`,
    { count }
  );
  return (
    <Row align="center" spacing={1}>
      <Icon svg={IconGuests} width={16} height={16} />
      <TextField>{guestsPluralLabel}</TextField>
    </Row>
  );
};

const useCalculatePrices = (ratePlan) => {
  const { money } = ratePlan;
  const roomTotalPrice = get(money, 'hostPayout');
  return {
    roomTotalPrice,
  };
};

const RoomsInformation = ({ property, quote }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { root, titleWrapper, arrow, propertyName, collapseWrapper } = useStyles({ collapsed });
  const { state } = useLocation();
  const [, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  const handleClick = () => {
    setCollapsed((prev) => !prev);
  };

  const ratePlan = quote?.rates?.ratePlans?.find((p) => p.ratePlan._id === state?.ratePlan?._id)?.ratePlan;

  const { roomTotalPrice } = useCalculatePrices(ratePlan);

  const accommodates = property?.accommodates;
  const money = state?.ratePlan?.money;
  const propertiesAndRooms = property?.title;
  const currency = money?.currency;

  return (
    <div className={root}>
      <Row align="center" justify="between" className={titleWrapper} onClick={handleClick}>
        <Row spacing={3} align="center">
          <Icon svg={Arrow} className={arrow} />
          <TextField variant="h4" bold className={propertyName}>
            {propertiesAndRooms}
          </TextField>
          <GuestsCount count={accommodates} />
        </Row>
        <TextField>
          <PriceConverter amount={roomTotalPrice} currency={currency} />
        </TextField>
      </Row>
      <Col className={collapseWrapper}>
        {isGroupReservationEnabled ? (
          <RatePlanV2 ratePlan={ratePlan} defaultCheckInTime={property.defaultCheckInTime} withAccordion={false} />
        ) : (
          <RatePlan ratePlan={ratePlan} defaultCheckInTime={property.defaultCheckInTime} />
        )}
      </Col>
    </div>
  );
};

const useWrapperStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Wrapper = () => {
  const { state } = useLocation();

  const property = state?.property;
  const quotes = state?.quoteData?.quote;
  const { root } = useWrapperStyles();
  if (!property || !quotes) {
    return null;
  }
  return (
    <div className={root}>
      {quotes.map((quote) => (
        <RoomsInformation key={quote?._id} quote={quote} property={property} />
      ))}
    </div>
  );
};

export default Wrapper;
